@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-bottom:18px;
    /* margin-left:21px; */
  }
  .toggle-switch input[type="checkbox"] {
    display: none;

  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: orange;
    /* border-radius: 25px; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: var(--text);
    /* border-radius: 50%; */
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    -webkit-transform: translateX(25px);
            transform: translateX(25px);
    background-color: var(--text);
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: orange;
  }
  
:root {
 --background: #f2ff7a;
 --text: #ff5858;
}

html,
body{
   
    font-size:18px;
    background-color: #f2ff7a;
    background-color: var(--background);
}

div{

    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:18px;
    width: 100vw; 

}

header{
    display: flex;
    justify-content: space-around;
    align-items:center;
    width:100vw;
    height: 88px;
}


button {
    color: orange;
    background-color:transparent;
    border:none;
    font-size:18px;
    cursor: pointer;
}
a{
    color: green;
    text-decoration:none;
    cursor: pointer;
}
.purple{
    color:purple;
    font-size: 33px;
}

.container{

    width: 93vw;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    cursor:pointer;
    
  }

  .pop{
    flex-direction: row;
    flex-wrap:wrap;
    height:120px;
    width:120px;
    object-fit: cover;
  }
  .video{
      height:120px;
      width:120px;
  }
  .canvas {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    justify-content: flex-end;
    
  }
  .row {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    max-width: 120px;
    max-height:120px;
    /* margin-top: 33px; */
  
  }

a{
   max-height:120px;
 
}
.lightMode {
    --text: #f2ff7a;
    --background: #ff5858;
    /* background-image: linear-gradient(#d063ff,#ff69eb); */
 }

 .icon{
     color: #ff5858;
     color: var(--text);
 }
 .searchbar{
    border-radius: 1%;
    height:23px;
    width: 180px; 
    font-family: 'Press Start 2P';
    font-size:16px;
    color: #f2ff7a;
    color: var(--background);
    background-color: #ff5858;
    background-color: var(--text);
    margin-bottom: 27px;
    /* margin-left: 9px; */
  }
  
