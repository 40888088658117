@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);

:root {
 --background: #f2ff7a;
 --text: #ff5858;
}

html,
body{
   
    font-size:18px;
    background-color: var(--background);
}

div{

    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:18px;
    width: 100vw; 

}

header{
    display: flex;
    justify-content: space-around;
    align-items:center;
    width:100vw;
    height: 88px;
}


button {
    color: orange;
    background-color:transparent;
    border:none;
    font-size:18px;
    cursor: pointer;
}
a{
    color: green;
    text-decoration:none;
    cursor: pointer;
}
.purple{
    color:purple;
    font-size: 33px;
}

.container{

    width: 93vw;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    cursor:pointer;
    
  }

  .pop{
    flex-direction: row;
    flex-wrap:wrap;
    height:120px;
    width:120px;
    object-fit: cover;
  }
  .video{
      height:120px;
      width:120px;
  }
  .canvas {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    justify-content: flex-end;
    
  }
  .row {
    display: flex; 
    flex-direction: row;
    flex-Wrap: wrap;
    max-width: 120px;
    max-height:120px;
    /* margin-top: 33px; */
  
  }

a{
   max-height:120px;
 
}
.lightMode {
    --text: #f2ff7a;
    --background: #ff5858;
    /* background-image: linear-gradient(#d063ff,#ff69eb); */
 }

 .icon{
     color: var(--text);
 }
 .searchbar{
    border-radius: 1%;
    height:23px;
    width: 180px; 
    font-family: 'Press Start 2P';
    font-size:16px;
    color: var(--background);
    background-color: var(--text);
    margin-bottom: 27px;
    /* margin-left: 9px; */
  }
  